import React from 'react'
import "./HomeBlog.css"

const HomeBlogHead = () => {
  return (
    <div className='homeBlogHead'>
      <h2>RECENT <span>POSTS</span></h2>
    </div>  
  )
}

export default HomeBlogHead
