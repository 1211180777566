import React from "react";
import "./Blog.css";
const BlogHeader = () => {
  return (
    <div className="blogHeader">
      <img
        src="https://img.freepik.com/free-photo/woman-showing-with-one-hand-mini-house-real-state-concept-ai-generative_123827-24098.jpg?uid=R153459844&ga=GA1.1.798303094.1721912336&semt=ais_hybrid"
        alt=""
      />
    </div>
  );
};

export default BlogHeader;
