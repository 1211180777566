import React from "react";

const GridCeilingProcess = () => {
  return (
    <div className="architectsHeadContainer">
      <h2>
        The Process of Installing a <span>Grid False Ceiling</span> in Bangalore
      </h2>

      <p>
        When you choose Build Ur Space for your grid false ceiling in Bangalore,
        you can expect a smooth and efficient process:
      </p>
      <li>
        <span>1. Initial Consultation</span>We begin with an initial
        consultation to discuss your needs and preferences. This allows us to
        understand your vision and provide tailored recommendations for your
        grid false ceiling.
      </li>
      <li>
        <span>2. Design Development</span> Our design team will create a
        detailed plan for your grid false ceiling in Bangalore , incorporating
        your ideas and preferences. We will present you with options for
        materials, colors, and designs, ensuring that you are involved in the
        decision-making process.
      </li>
      <li>
        <span>3. Material Selection </span>Once the design is approved, we will
        assist you in selecting the best materials for your grid false ceiling.
        Our team will guide you in choosing options that fit your style, budget,
        and functional requirements.
      </li>
      <li>
        <span>4. Installation Planning</span>After finalizing the materials, we
        will schedule the installation at a time that is convenient for you. Our
        team will prepare the site, ensuring that all necessary preparations are
        in place before installation begins.
      </li>
      <li>
        <span> 5. Professional Installation</span> On the day of installation,
        our skilled professionals will arrive on-site to execute the plan. We
        take pride in our meticulous approach, ensuring that your grid false
        ceiling in Bangalore is installed correctly and meets our high
        standards.
      </li>
      <li>
        <span>6. Final Inspection</span>Once the installation is complete, we
        will conduct a thorough inspection to ensure everything is in order. We
        want to ensure that you are completely satisfied with your new ceiling
        and that it meets your expectations.
      </li>
    </div>
  );
};

export default GridCeilingProcess;
